// Fonts
/* @import url('https://fonts.googleapis.com/css?family=Nunito'); */
@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freckle+Face&display=swap');



// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// styles
@import 'style';

