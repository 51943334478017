body {
    margin: 0;
    font-family: "Cabin Sketch", sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    text-align: left;
    background-color: #fff;
    font-display: swap;
  }

  
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #96bf28;
}
a {
    color: #96bf28;
}

.pre-style{
    font-family: "Cabin Sketch", sans-serif;
    font-size: 16px; 
    color:#5b5b5b; 
    white-space: pre-line;
    font-display: swap;
}

.transparent {
    background-color: transparent;
}
.navbar {
    margin-bottom: 3em;
}
.nav-link {
    font-family: "Freckle Face", sans-serif;
    font-size: 1.6rem;
    font-weight: 100;
    line-height: 1.6;
    color: #df641e;
    display: block;
    padding: 0.5rem 1rem;
    font-display: swap;
}
.portada {
    background-color: #003700;
}
.bg-corporatiu {
    background-color: #96bf28 !important;
}

.bg-taronja {
    background-color: #ff9800 !important;
    color: #fff !important;
}

.bg-taronja-fort {
    background-color: rgb(255, 119, 0) !important;
    color: #fff !important;
}



.bg-taronja-fort-footer {
    background-color: rgb(255, 119, 0) !important;
    margin-top: 4em;
    color: #fff !important;
    background: linear-gradient(
        0deg,
        rgb(255, 132, 0) 10%,
        rgba(255, 152, 0, 1) 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-taronja-grad {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 60%,
        rgba(255, 152, 0, 1) 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
    /* color: #fff !important; */
}

.bg-taronja-grad-gran {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 152, 0, 1) 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-corporatiu-grad {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 60%,
        #96bf28 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-mixtas-grad {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 80%,
        #96bf28 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-corporatiu-grad-gran {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 20%,
        #96bf28 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-taronja-fort-grad {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 60%,
        rgb(255, 119, 0) 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-amarillo-grad {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 60%,
        #fddd43 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-roig-grad {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 60%,
        #fe481f 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-marron-grad {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 60%,
        #cda87a 100%
    ) !important;
    box-shadow: 0 -8px 15px -5px rgb(0 0 0 / 15%) !important;
}

.bg-corporatiu {
    background-color: #96bf27 !important;
    color: #fff;
}

.bg-negre {
    background-color: #141415 !important;
    color: #fff !important;
}
.text-1em {
    font-size: 1em !important;
}

.text-2em {
    font-size: 2em !important;
}

.text-3em {
    font-size: 3em !important;
}

.text-4em {
    font-size: 4em !important;
}

.container-gran,
.container-gran-fluid,
.container-gran-xl,
.container-gran-lg,
.container-gran-md,
.container-gran-sm {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container-gran-sm,
    .container-gran {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container-gran-md,
    .container-gran-sm,
    .container-gran {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container-gran-lg,
    .container-gran-md,
    .container-gran-sm,
    .container-gran {
        max-width: 960px;
    }
}
@media (min-width: 1800px) {
    .container-gran-xl,
    .container-gran-lg,
    .container-gran-md,
    .container-gran-sm,
    .container-gran {
        max-width: 1740px;
    }
}

.container-toxo,
.container-toxo-fluid,
.container-toxo-xl,
.container-toxo-lg,
.container-toxo-md,
.container-toxo-sm {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container-toxo-sm,
    .container-toxo {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container-toxo-md,
    .container-toxo-sm,
    .container-toxo {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container-toxo-lg,
    .container-toxo-md,
    .container-toxo-sm,
    .container-toxo {
        max-width: 960px;
    }
}
@media (min-width: 1400px) {
    .container-toxo-xl,
    .container-toxo-lg,
    .container-toxo-md,
    .container-toxo-sm,
    .container-toxo {
        max-width: 1340px;
    }
}

.formu-table {
    width: 80%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: 1em;
    border-radius: 5px;
    color: #212529;
    background-color: rgb(242, 242, 242);
}
.formu-table th {
    text-align: center;
    background-color: rgb(222, 222, 222);
}

.img-listado {
    width: 40px;
    margin-top: -10px;
}

.img-40 {
    width: 40px;
}

.showPass {
    margin-bottom: 2em;
}

.titulo-producto {
    margin-top: 1em;
    font-size: 1.2em;
    font-weight: bold;
}

.card-tenda {
    border: transparent;
}

.btn-corporatiu {
    background-color: #96bf27;
    color: #fff;
    font-weight: bold;
}

.btn-roig-fluix {
  background-color: #f57370 !important;
  color: #fff !important;
}

.color-taronja {
    color: #ff9800 !important;
}
.color-taronja-fort {
    color: #df641e !important;
}

.color-corporatiu {
    color: #96bf27 !important;
}

.color_gris {
    color: #848484;
}
.color_gris_clar {
    color: #cecece;
}

.bg-gris {
    background-color: #fcfbfb;
}

.color-red {
    color: red;
}

.color-rosa {
    color: #f67c7f;
}
.color-verd {
    color: green;
}
.color-blue {
    color: #3490dc;
}

.color-info {
    color: #76cef5fc;
}
.color-groc {
    color: #e1a21b;
}
.color-amarillo {
    color: #fddd43;
}

.color-morat {
    color: #7e57c2;
}

.color-negre {
    color: #3a3a3a;
}

.color-marron {
    color: #9e7040;
}

.btn-fulla {
    font-size: 1.2em;
    font-weight: 900;
    height: 45px;
    padding-right: 2em;
    padding-left: 2em;
    border-radius: 0 75% / 0 100%;
    max-width: 150px;
    -moz-border-radius: 0 75% / 0 100%;
    -webkit-border-radius: 0 75% / 0 100%;
}

@media (min-width: 576px) {
    .btn-fulla-sm {
        max-width: 100px;
    }
}
@media (min-width: 768px) {
    .btn-fulla-md {
        max-width: 100px;
    }
}
@media (min-width: 992px) {
    .btn-fulla-lg {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .btn-fulla-xl {
        max-width: 150px;
    }
}

.btn-fulla-gran {
    font-size: 1.2em;
    font-weight: 900;
    height: 65px;
    padding-right: 2em;
    padding-left: 2em;
    border-radius: 0 75% / 0 100%;
    max-width: 250px;
    /* -moz-border-radius: 0 75% / 0 100%;
      -webkit-border-radius: 0 75% / 0 100%; */
}

.btn-fulla:hover {
    background-color: #f78c05;
    transform: rotateX(30deg);
}

body {
    background-color: #fff;
}

.margin-top {
    margin-top: 1em;
}

.margin-top-05 {
    margin-top: 0.5em;
}

.margin-bottom {
    margin-bottom: 1em;
}

.margin-right {
    margin-right: 1em;
}

.margin-left {
    margin-left: 1em;
}
.margin-left_10 {
    margin-left: 10px;
}

.table-gris td {
    color: #848484;
}

.text-center-row th,
.text-center-row td {
    text-align: center;
}

.strong {
    font-weight: 900;
}

.ccv_true {
    font-size: 20px;
    border: transparent;
    background: #fff;
    border: none;
    color:#96bf27;
}
.ccv_false {
    font-size: 20px;
    border: transparent;
    background: #fff;
    border: none;
    color: red;
}

.input_bold_20 {
    font-size: 20px;
    border: transparent;
    background: #fff;
    border: none;
}
.input_bold {
    font-size: 18px;
    font-weight: bold;
    border: transparent;
    background: #fff;
    border: none;
}

.input_false {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: red;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid red;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* SWITCH POTENT */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #96bf27;
}

input:focus + .slider {
    box-shadow: 0 0 1px #96bf27;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
/* SWITCH POTENT */

.met_pago_switch {
    display: block;
    font-size: 5em;
    border: transparent;
    color: #96bf27;
    border: none;
}

.card-pago {
    display: block;
    border: transparent;
    padding: 1em;
}

.card-pago:hover {
    display: block;
    border: 1px solid #96bf27;
    box-shadow: 0 1.125rem 1.25rem rgb(0 0 0 / 8%) !important;
}
.bar_informes {
    background: rgb(138, 179, 29);
    background: linear-gradient(
        90deg,
        rgba(138, 179, 29, 1) 0%,
        rgba(110, 140, 29, 1) 100%
    );
}
.padding_1 {
    padding: 1em;
}
